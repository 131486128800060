    <!-- 个人信息侧边栏 -->
<template>
  <div class="sidecontainer">
    <div class="sideManu-name">
      个人中心
    </div>
    <ul class="sideManue">
      <li
        v-for="(item,index) in tabList"
        :key="item.key"
        :class="{'active':tabIdx==item.key}"
      >
        <i class="active_line"></i>
        <span class="icon iconfont" v-html="item.iconleft"></span>
        <div @click="goto(item,index)">{{item.label}}</div>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  name: 'side',

  data () {
    return {
      tabIdx: 'BaseInfo',
      tabList: [
        {
          iconleft:'&#xe629;',
          label: '个人信息',
          router: '/Mine/BaseInfo',
          key: 'BaseInfo'
        },
        {
          iconleft:'&#xe62b;',
          label: '实名认证',
          router: '/Mine/RealName',
          key: 'RealName'
        },
        {
          iconleft:'&#xe631;',
          label: '权利人管理',
          router: '/Mine/Obligee',
          key: 'Obligee'
        },
        {
          iconleft:'&#xe626;',
          label: '钱包',
          router: '/Mine/MoneyBag',
          key: 'MoneyBag'
        },
        {
          iconleft:'&#xe62d;',
          label: 'VIP权益',
          router: '/Mine/Vip',
          key: 'MineVip'
        },
        {
          iconleft:'&#xe62c;',
          label: '我的存证',
          router: '/Mine/ExistingEvidence',
          key: 'MineExistingEvidence'
        },
        {
          iconleft:'&#xe62e;',
          label: '我的登记',
          router: '/Mine/MyRegister',
          key: 'MyRegister'
        },
         {
          iconleft:'&#xe62a;',
          label: '我的监测',
          router: '/Mine/Monitor',
          key: 'MineMonitor'
        },
         {
          iconleft:'&#xe630;',
          label: '我的取证',
          router: '/Mine/ObtainEvidence',
          key: 'MineObtainEvidence'
        },
        {
          iconleft:'&#xe628;',
          label: '合约管理',
          router: '/Mine/OnSale',
          key: 'MineOnSale'
         
        },
        {
          iconleft:'&#xe62f;',
          label: '消息通知',
          router: '/Mine/Notice',
          key: 'Notice'
        },
        
      ]
    }
  },
  watch:{
    $route(to,from){
      console.log(to.name)
     this.tabIdx=to.name
    }
},
  mounted () {
    console.log( this.$route.name)
    this.tabIdx = this.$route.name
  },
  methods: {
    goto (item, index) {
      this.$router.push({
        path: item.router,
        query: {}
      });
      this.tabIdx = item.key
    }
  }
}
</script>

<style lang='scss' scoped>
.sidecontainer{
  width: 192px;
  // height: 670px;
  padding-bottom: 16px;
  height: 100%;
  background: #F2F8FF;
}
.sideManu-name{
  font-size: 18px;
  font-weight: 500;
  height: 88px;
  line-height: 88px;
  padding-left: 24px;
}
.sideManue {
  li{
    padding:14px 0 14px 54px;
    line-height: 20px;
    position: relative;
    .icon{
    font-size: 22px;
    position: absolute;
    left: 24px;
    top: 14px;
    opacity: 0.8;
    }
    cursor: pointer;
  }
  li.active,li:hover {
    color: #007DFF;
    background: #ffffff;
    .active_line{
      display: block;
      position: absolute;
      left: 0;
      top: 4px;
      width: 2px;
      height: 40px;
      background: #007DFF;
    }
    .icon{
      opacity: 1;
    }
  }
}
</style>
